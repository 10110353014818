import React from 'react';
import PropTypes from 'prop-types';
import icons from './../../../assets/icons';

function IconComponent(props) {
    const {icon} = props;

    const data = icons[icon];

    return (
        <div className="component iconcomponent">
            <div className="content-block">
                <img src={data.icon} alt={data.dutchReadable} style={{}}/>
            </div>
            <hr/>
            <div className="title">
                <h2>
                    {data.dutchReadable}
                </h2>
            </div>
        </div>
    )
}

IconComponent.propTypes = {
    icon: PropTypes.string,
};

export default IconComponent;
