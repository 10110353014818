import React, {Component} from 'react';
import './theme.scss';
import SOS from "./sos";
import PropTypes from "prop-types";

class LegacyKioskComponent extends Component {

    static propTypes = {
        brand: PropTypes.any,
        theme: PropTypes.any,
        locationId: PropTypes.number,
    };

    getComponent = () => {
        const {brand, theme, locationId} = this.props;
        switch (brand, theme) {
            case 'sos', 'primary-school':
            default: {
                return <SOS locationId={locationId}/>
            }
        }
    };

    render() {
        const scale = Math.min(
            window.screen.availWidth / 1920,
            window.screen.availHeight / 1080
        );

        return <div className="legacycomponent" style={{transform: `scale(${scale})`}}>
            {this.getComponent()}
        </div>
    }
}

export default LegacyKioskComponent;