import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(<App/>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

const serviceWorkerConfig = {};

serviceWorkerConfig.onUpdate = (registration) => {
    console.log('onUpdate called');
    console.log('window.require', window.require);
    if (window && window.require) {
        const {ipcRenderer} = window.require('electron');
        if (ipcRenderer) {
            console.log('Electron, sending reboot signal!');
            ipcRenderer.send('reboot-window');
        }
    } else {
        console.log(registration);
        registration.update()
            .then(res => {
                console.log('update result', res);
            })
            .catch(err => {
                console.log('update error', err);
            });
        console.log('Not electron, need to reboot to reload');
    }
};

serviceWorkerConfig.onSuccess = serviceWorkerConfig.onUpdate;
serviceWorker.register(serviceWorkerConfig);
