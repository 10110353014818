import React, {Component, Fragment} from "react";
import weegschaal1 from '../../../assets/legacy/sos/media/weegschaal1.png';
import weegschaal2 from '../../../assets/legacy/sos/media/weegschaal2.png';
import weegschaal3 from '../../../assets/legacy/sos/media/weegschaal3.png';
import houses from '../../../assets/legacy/sos/media/house.png';
import trees from '../../../assets/legacy/sos/media/tree.png';
import {formatNumber} from "../../../lib/formatting";
import * as metaphors from "../MetaphorComponent/metaphors";
import * as config from "../../../config";
import PropTypes from "prop-types";

class SOS extends Component {

    static propTypes = {
        locationId: PropTypes.number,
    };

    metaphorTimer = null;
    state = {
        current: {
            currentPower: 0,
            currentUsage: 0,
            message: '',
            nettoUsage: 0,
            totalPower: 0,
            logo: '',
            cityWeather: ''
        },
        displayBlock: 'bespaard',
        currentMetaphor: 0,
        metaphorList: [{}]
    }

    fetchCurrent = async () => {
        const request = await fetch(`http://kiosk.slimopgewekt.nl/actueel.php?&s=${this.props.locationId}`);
        const response = await request.json();
        return response;
    };

    componentDidMount = async () => {
        const current = await this.fetchCurrent();
        const metaphorList = config.metaphors['sos']['primary-school'].map(key => {
            return {
                key,
                ...metaphors.default[key]
            }
        });

        this.setState({current, metaphorList});
        this.metaphorTimer = setInterval(this.updateMetaphor.bind(this), 5000)
    };

    componentWillUnmount() {
        clearInterval(this.metaphorTimer);
    }

    updateMetaphor = () => {
        let current = this.state.currentMetaphor + 1;
        if (current >= this.state.metaphorList.length)
            current = 0;
        this.setState({currentMetaphor: current});
    };

    getMetaphor = () => {
        return this.state.metaphorList[this.state.currentMetaphor];
    };

    render() {
        const {current, displayBlock} = this.state;
        const weegschaal = current.nettoUsage > 1 ? weegschaal3 :
            current.nettoUsage < -1 ? weegschaal1 : weegschaal2;
        const metaphor = this.getMetaphor();
        const metaphorImage = require(`../../../assets/metaphors/${metaphor.image || 'tv.png'}`);

        return (
            <div id="kiosk">
                <div id="bigchart" className="whitebox">
                    <div className="chart-menu clearfix">
                        <ul>
                            <li><a id="chart_today">Vandaag</a></li>
                            <li><a id="chart_30days">Laatste 30 dagen</a></li>
                            <li><a id="chart_12months">Laatste 12 maanden</a></li>
                            <li><a id="total" className="selected">Totaal</a></li>
                        </ul>
                    </div>
                    <div id="chart" data-highcharts-chart="0">
                        <div className="highcharts-container" id="highcharts-0"
                             style={{display: displayBlock != 'bespaard' ? 'block' : 'none'}}>
                        </div>
                        <div id="totalbox" style={{display: displayBlock == 'bespaard' ? 'block' : 'none'}}>
                            <h2>Totaal opgewekt:</h2>
                            <span id="totalPower">150.998</span> kWh = <span
                            id="totalHouses">{formatNumber(current.totalPower * metaphors.houses.factor, 1)}</span> huizen <img
                            src={houses} id="house" alt="huizen"/>
                            <br/><br/><br/>
                            <h2>Totaal CO<sub>2</sub> bespaard:</h2>
                            <span
                                id="totalTrees">{formatNumber(current.totalPower * metaphors.trees.factor, 1)}</span> bomen <img
                            src={trees} id="tree" alt="boom"/>
                        </div>
                    </div>
                </div>


                <div id="weather" className="whitebox">
                    {current.cityWeather ?
                        <Fragment>
                            <iframe id="rain"
                                    src={`https://www.weeronline.nl/Go/ExternalWidgetsNew/RainAnd2DaysCity?gid=${current.cityWeather}&temperatureScale=Celsius&defaultSettings=False`}
                                    width="300" height="350" frameBorder="0" scrolling="no" style={{border: 'none'}}
                                    allowTransparency="true"/>
                            <iframe id="weatherforecast"
                                    src={`https://www.weeronline.nl/Go/ExternalWidgetsNew/ThreeDaysCity?gid=${current.cityWeather}&sizeType=1&temperatureScale=Celsius&defaultSettings=False`}
                                    width="235" height="216" frameBorder="0" scrolling="no" style={{border: 'none'}}
                                    allowTransparency="true"/>
                        </Fragment> : ''}
                    <small>©WeerOnline.nl</small>
                </div>


                <div id="actueel" className="infobox whitebox">

                    <div id="actueel-slideshow" style={{height: '250px', width: '420px', position: 'relative'}}>
                        <h2>Onze school wekt nu op:<br/>
                            <span id="currentPower">{formatNumber(current.currentPower, 1)}</span> kiloWatt</h2>
                        <span id="equalsign">=</span>
                        <div className="actueel-metafor">
                            <span
                                className="value">{formatNumber(current.currentPower * 1000 * metaphor.factor)}</span> {metaphor.text}
                            <img src={metaphorImage} alt=""/>
                        </div>
                    </div>

                </div>


                <div id="verbruik" className="infobox whitebox">
                    <h2>Onze school verbruikt nu:</h2>
                    <span id="currentUsage">11,72</span> kiloWatt
                </div>


                <div id="evenwicht" className="infobox whitebox">
                    <h2 style={{fontSize: '0.6em'}}><em>Wekken we meer op dan we verbruiken?</em></h2>
                    <div>
                        <img src={weegschaal}/>
                    </div>

                </div>


                <div id="schoolnieuws" className="infobox whitebox">
                    <h2>Schoolnieuws:</h2>
                    <span id="message" dangerouslySetInnerHTML={{__html: current.message}}>

                </span>
                </div>

                <img src={`https://kiosk.slimopgewekt.nl/media/logos/${current.logo}`} id="logo"/>
                <div id="stekker"/>
            </div>
        );
    }
}

export default SOS;