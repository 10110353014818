import React from 'react';
import PanelInfoOptions from './PanelInfoOptions';

function getValue(panelInfo, info) {
    switch(info){
        case 'POWER':
            return `${panelInfo.Power} kW`;
        default:
            return panelInfo.Panels;
    }
}

function PanelInfoComponent(props) {
    const title = PanelInfoOptions.find(option => option.info === props.info).dutchReadable;
    const value = getValue(props.location.panelInfo, props.info);

    return (
        <div className="component panelinfocomponent">
            <div className="content-block">
                <p className="value-display disable-text-transform">
                    {value}
                </p>
            </div>
            <hr/>
            <div className="title">
                <h2>
                    {title}
                </h2>
            </div>
        </div>
    )
}

PanelInfoComponent.propTypes = {};

PanelInfoComponent.defaultProps = {
    location: {
        panelInfo: {
            Panels: 0
        }
    }
};

export default PanelInfoComponent;
