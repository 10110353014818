import React, {Component, Fragment} from 'react';
import {formatDate} from "../../lib/formatting";

class Status extends Component {

    state = {
        status: 'online',
    };

    componentDidMount() {
        window.addEventListener('online', this.updateOnlineStatus);
        window.addEventListener('offline', this.updateOnlineStatus);
        this.updateOnlineStatus();
    }

    componentWillUnmount() {
        window.removeEventListener('online', this.updateOnlineStatus);
        window.removeEventListener('offline', this.updateOnlineStatus);
    }

    updateOnlineStatus = () => {
        const status = navigator.onLine ? "online" : "offline";

        if (this.state.status !== status) {
            this.props.reload()
        }

        this.setState({status});
    };

    render() {
        const {status} = this.state;
        const {lastUpdate} = this.props;

        return (
            <div className="status">
                <p className={status}>{status}</p>
                {status === 'offline' ?
                    <p className='last-update'>Laatste update: <br/>{formatDate(lastUpdate)}</p>
                    : <Fragment/>
                }
            </div>
        );
    }
}

export default Status;