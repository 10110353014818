import React, {Fragment, useState} from 'react';
import Timer from "./Timer";
import defaultConfig from './../../config.json';
import ContentBlock from "../ContentBlock";
import PropTypes from "prop-types";

function Slides(props) {
    let [currentSlideIndex, updateSlideIndex] = useState(0);
    if (props.slides.length === 0)
        return (<Fragment/>);
    
    if (currentSlideIndex > props.slides.length - 1) {
        updateSlideIndex(0);
        currentSlideIndex = 0;
    }

    const currentSlide = props.slides[currentSlideIndex];

    let timer;
    if (props.slides.length > 1) {
        const timeout = (currentSlide.time || defaultConfig.slides.defaultSlideTime) * 1000;

        setTimeout(() => {
            let nextIndex = currentSlideIndex + 1;
            if (nextIndex >= props.slides.length)
                nextIndex = 0;
            updateSlideIndex(nextIndex);
        }, timeout);

        timer = (
            <div className="timer">
                <div className="circle">
                    <Timer slide={currentSlide} timeout={timeout}/>
                </div>
            </div>
        );
    }
    return (
        <Fragment>
            <div className="content">
                <ContentBlock position={1} slide={currentSlide} amountOfSlides={props.slides.length} brand={props.brand} theme={props.theme} location={props.location}/>
                <ContentBlock position={2} slide={currentSlide} amountOfSlides={props.slides.length} brand={props.brand} theme={props.theme} location={props.location}/>
                <ContentBlock position={3} slide={currentSlide} amountOfSlides={props.slides.length} brand={props.brand} theme={props.theme} location={props.location}/>
                <ContentBlock position={4} slide={currentSlide} amountOfSlides={props.slides.length} brand={props.brand} theme={props.theme} location={props.location}/>
                <ContentBlock position={5} slide={currentSlide} amountOfSlides={props.slides.length} brand={props.brand} theme={props.theme} location={props.location}/>
                <ContentBlock position={6} slide={currentSlide} amountOfSlides={props.slides.length} brand={props.brand} theme={props.theme} location={props.location}/>
            </div>
            {timer}
        </Fragment>
    );
}

Slides.propTypes = {
    slides: PropTypes.array,
};

export default Slides;
