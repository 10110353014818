import {PERSIST_DATA_KEY} from "../constants/CONSTANTS";

export function persistData(data) {
    window.localStorage.setItem(PERSIST_DATA_KEY, JSON.stringify(data));
}

export function retrieveData() {
    const dataString = window.localStorage.getItem(PERSIST_DATA_KEY);
    if(!dataString)
        return {};

    return JSON.parse(dataString);
}