import React, {Component, Fragment} from 'react';
import QR from 'react-qr-code';
import PropTypes from "prop-types";

class QRCode extends Component {

    static propTypes = {
        screenCode: PropTypes.string,
    };

    render() {
        return (
            <Fragment>
                <div style={{
                    height: '100vh',
                    display: 'flex', // make us of Flexbox
                    alignItems: 'center', // does vertically center the desired content
                    justifyContent: 'center', // horizontally centers single line items
                    textAlign: 'center', // optional, but helps horizontally center text that breaks into multiple lines
                    flexDirection: 'column',
                }}>
                    <h1>Activeer scherm</h1>
                    <QR value={this.props.screenCode}/>
                </div>
            </Fragment>
        )
    }
}

export default QRCode;