import React, {useState} from 'react';
import icons from './../../../assets/icons';
import metaphors from './metaphors';
import defaultConfig from "../../../config";
import {formatNumber} from "../../../lib/formatting";

function random_item(items) {
    return items[Math.floor(Math.random() * items.length)];
}

function getRandomMetaphor(current) {
    const keys = Object.keys(metaphors);
    let randomKey = random_item(keys);
    let result = metaphors[randomKey];

    while (result === current || result.image === '') {
        randomKey = random_item(keys);
        result = metaphors[randomKey];
    }

    return result;
}

function MetaphorComponent(props) {
    const {amountOfSlides, yieldData} = props;
    let [currentMetaphor, updateMetaphorKey] = useState(getRandomMetaphor());

    if (amountOfSlides === 1) {
        const {slide} = props;
        const timeout = (slide.time || defaultConfig.slides.defaultSlideTime) * 1000;

        setTimeout(() => {
            updateMetaphorKey(getRandomMetaphor(currentMetaphor))
        }, timeout);

    }

    return (
        <div className="component metaphorcomponent">
            <div className="content-block">
                <img src={`/metaphors/${currentMetaphor.image}`} alt={currentMetaphor.text} style={{}}/>
            </div>
            <hr/>
            <div className="title">
                <h2>
                    {parseInt(formatNumber((yieldData.Yield * 1000) * currentMetaphor.factor))} {currentMetaphor.text}
                </h2>
            </div>
        </div>
    )
}

MetaphorComponent.propTypes = {};

export default MetaphorComponent;
