import React from 'react';
import PropTypes from "prop-types";
import logo_sos from './../../assets/images/SOS logo.png'
import logo_ozio from './../../assets/images/OZIO logo.png';

function Logo(props) {
    const {brand} = props;

    const logo = brand === 'sos' ? logo_sos : logo_ozio;

    return (
        <div className="logo">
            <img src={logo} alt={'logo'}/>
        </div>
    );
}

Logo.propTypes = {
    brand: PropTypes.any,
};

export default Logo;
