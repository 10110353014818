export function getCode() {
    const urlParams = new URLSearchParams(window.location.search);
    const codeParam = urlParams.get('code');
    if (codeParam)
        return codeParam;

    let code = window.localStorage.getItem('code');

    if (code)
        return code;
    return null;
}

export function setCode(code) {
    window.localStorage.setItem('code', code);
}