import React, {Fragment, useState} from 'react';
import {CircularProgressbar} from 'react-circular-progressbar';
import {getThemeColor} from "../../lib/theme";

function Timer(props) {
    const [timerValue, updateTimerValue] = useState(100.0);
    const [startTime, updateStartTime] = useState(new Date().getTime());

    const progressTimerFunc = () => {
        const milliSeconds = new Date().getTime() + 5;
        const diff = milliSeconds - startTime;
        if (diff >= props.timeout) {
            updateTimerValue(100);
            updateStartTime(new Date().getTime());
            return;
        }

        const newValue = 100 - (diff / props.timeout) * 100;

        updateTimerValue(newValue);// TODO add custom time length instead of 5
    };

    setTimeout(progressTimerFunc, 15);
    const primaryColor = getThemeColor();
    return (
        <Fragment>
            <CircularProgressbar
                value={timerValue}
                strokeWidth={50}
                textForPercentage={null}
                styles={{
                    path: {strokeLinecap: 'butt', stroke: primaryColor},
                }}
            />
        </Fragment>
    )
}

export default Timer;