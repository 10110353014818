import React, {Component} from 'react';
import socketio from 'socket.io-client';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faSpinner} from '@fortawesome/free-solid-svg-icons'
import './App.scss';
import Theme from "./components/Theme";
import Logo from "./components/Logo";
import LocationName from "./components/LocationName";
import {getCode, setCode} from './lib/code';
import CONSTANTS from "./constants/CONSTANTS";
import Slides from "./components/Slides";
import Status from "./components/Status";
import {persistData, retrieveData} from "./lib/persist";
import QRCode from "./components/QRCode";

class App extends Component {

    screenUpdateTimeout = null;

    state = {
        screenCode: getCode(),
        location: undefined,
        activated: false,
        loading: true,
        theme: '',
        brand: '',
        io: null,
        slides: [],
        lastUpdate: Date.now(),
    };

    componentDidMount = () => {
        const socket = socketio(CONSTANTS.SERVER_URL);

        if (!this.state.screenCode) {
            fetch(`${CONSTANTS.SERVER_URL}/energyscreens`, {
                method: 'POST'
            })
                .then(res => res.json())
                .then(body => {
                    setCode(body.energyscreens.Code);
                    this.setState({screenCode: body.energyscreens.Code, loading: false});
                })
        } else {
            this.loadScreenData();
        }

        socket.on('connect', () => {
            if (this.state.screenCode)
                socket.emit('screen-online', this.state.screenCode);
        });

        socket.on('disconnect', function () {
            //TODO Show message on disconnect or try to reconnect
        });

        socket.on('reload-data', this.updateScreen);

        this.setState({io: socket});
    };

    updateScreen = () => {
        if (this.screenUpdateTimeout) {
            clearTimeout(this.screenUpdateTimeout);
        }
        this.screenUpdateTimeout = setTimeout(this.loadScreenData, 500);
    };

    loadScreenData = () => {
        fetch(`${CONSTANTS.SERVER_URL}/energyscreens/${this.state.screenCode}`, {
            method: 'GET'
        })
            .then(res => res.json())
            .then(body => {
                const newState = {
                    loading: false,
                    slides: body.energyscreens.Slides || [],
                    theme: body.energyscreens.Theme || 'sos',
                    brand: body.energyscreens.Brand || 'sos',
                    location: {},
                };

                if (body.energyscreens.Username) {
                    newState.activated = true;
                }

                if (body.location) {
                    newState.location = body.location;
                }

                if (newState.activated) {
                    newState.lastUpdate = Date.now();
                }

                this.setState(newState);
                persistData(newState);
            })
            .catch(() => {
                const newState = retrieveData();
                newState.loading = false;

                this.setState(newState);
            });
    };

    render() {
        const {loading, theme, brand, activated, slides, location, lastUpdate, screenCode} = this.state;

        if (loading) {
            return (
                <div style={{
                    fontSize: '5em',
                    color: '#4040CC',
                    height: '100vh',
                    display: 'flex', // make us of Flexbox
                    alignItems: 'center', // does vertically center the desired content
                    justifyContent: 'center', // horizontally centers single line items
                    textAlign: 'center', // optional, but helps horizontally center text that breaks into multiple lines
                }}>
                    <FontAwesomeIcon icon={faSpinner} spin/>
                </div>
            )
        }

        if (activated)
            return (
                <Theme theme={theme} brand={brand}>
                    <div className="grid-container">
                        <Logo brand={brand}/>
                        <LocationName theme={theme} location={location}/>
                        <Slides slides={slides} brand={brand} theme={theme} location={location}/>
                        <Status reload={this.loadScreenData} lastUpdate={lastUpdate}/>
                    </div>
                </Theme>
            );

        return (
            <QRCode screenCode={screenCode}/>
        )
    }
}

export default App;
