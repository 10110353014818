import React from 'react';
import PropTypes from 'prop-types';

function LocationName(props) {
    return (
        <div className="location-name">
            <h1>{props.location.Name}</h1>
        </div>
    );
}

LocationName.propTypes = {
    location: PropTypes.shape({
        Name: PropTypes.string,
    })
};

LocationName.defaultProps = {
    location: {
        Name: ''
    }
};

export default LocationName;
