const numberFormatter = new Intl.NumberFormat('nl-NL', {style: 'decimal',});
const dateFormatter = new Intl.DateTimeFormat('nl-NL', {
    weekday: 'short',
    day: '2-digit',
    month: 'long',
    hour: '2-digit',
    minute: '2-digit',
});

export const formatNumber = number => numberFormatter.format(number);
export const formatDate = date => dateFormatter.format(date);