import React from 'react';
import {formatNumber} from "../../../lib/formatting";
import PropTypes from 'prop-types';

function DataComponent(props) {
    const {yieldData} = props;

    return (
        <div className="component datacomponent">
            <div className="content-block">
                <p className="value-display">
                    <span className="value">{formatNumber(yieldData.Yield)}</span>
                </p>
            </div>
            <hr/>
            <div className="title">
                <h2>
                    <span className='disable-text-transform'>kWh</span> opgewekt
                </h2>
            </div>
        </div>
    )
}

DataComponent.propTypes = {
    title: PropTypes.string,
    yieldData: PropTypes.any,
    // unit: PropTypes.any,
};

DataComponent.defaultProps = {
    power: 0,
};

export default DataComponent;
