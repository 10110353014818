import AMOUNT_KWH from './AMOUNT KWH.png';
import LED from './LED.png';
import SUN from './SUN.png';
import YIELD_YESTERDAY from './YIELD YESTERDAY.png';

export default {
    AMOUNT_KWH: {
        icon: AMOUNT_KWH,
        dutchReadable: 'Aantal KWH',
    },
    LED: {
        icon: LED,
        dutchReadable: 'led',
    },
    SUN: {
        icon: SUN,
        dutchReadable: 'Zon',
    },
    YIELD_YESTERDAY:{
        icon: YIELD_YESTERDAY,
        dutchReadable: 'Opwek gisteren'
    }
}
